import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactForm } from './ContactForm';
import { flexCenter } from './styles/classes';

const Section = styled.section`
    background-color: ${({ theme }) => theme.colors.gray2};

    > form {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    @media (max-width: 1023px) {
        > .gatsby-image-wrapper {
            aspect-ratio: 375 / 322;
        }
    }

    @media (min-width: 1024px) {
        ${flexCenter};
        justify-content: flex-start;

        > .gatsby-image-wrapper {
            flex-shrink: 0.4;
            margin-right: ${({ theme }) => theme.spacing.md};
        }
    }

    @media (min-width: 1600px) {
        justify-content: center;
    }
`;

export const ContactUs = () => {
    return (
        <Section>
            <StaticImage
                src="../../../assets/images/layout/contact.jpg"
                alt="rachel more with a patient"
                width={703}
                height={914}
                transformOptions={{ cropFocus: 'north' }}
            />
            <ContactForm />
        </Section>
    );
};
