exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-about-us-meet-the-team-tsx": () => import("./../../../src/pages/about-us/meet-the-team.tsx" /* webpackChunkName: "component---src-pages-about-us-meet-the-team-tsx" */),
  "component---src-pages-about-us-our-story-tsx": () => import("./../../../src/pages/about-us/our-story.tsx" /* webpackChunkName: "component---src-pages-about-us-our-story-tsx" */),
  "component---src-pages-bridal-bridesmaids-package-tsx": () => import("./../../../src/pages/bridal-bridesmaids-package.tsx" /* webpackChunkName: "component---src-pages-bridal-bridesmaids-package-tsx" */),
  "component---src-pages-contact-information-tsx": () => import("./../../../src/pages/contact-information.tsx" /* webpackChunkName: "component---src-pages-contact-information-tsx" */),
  "component---src-pages-gallery-before-and-after-tsx": () => import("./../../../src/pages/gallery/before-and-after.tsx" /* webpackChunkName: "component---src-pages-gallery-before-and-after-tsx" */),
  "component---src-pages-gallery-index-tsx": () => import("./../../../src/pages/gallery/index.tsx" /* webpackChunkName: "component---src-pages-gallery-index-tsx" */),
  "component---src-pages-gallery-our-facility-tsx": () => import("./../../../src/pages/gallery/our-facility.tsx" /* webpackChunkName: "component---src-pages-gallery-our-facility-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-products-tsx": () => import("./../../../src/pages/our-products.tsx" /* webpackChunkName: "component---src-pages-our-products-tsx" */),
  "component---src-pages-our-services-tsx": () => import("./../../../src/pages/our-services.tsx" /* webpackChunkName: "component---src-pages-our-services-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-vanity-club-tsx": () => import("./../../../src/pages/vanity-club.tsx" /* webpackChunkName: "component---src-pages-vanity-club-tsx" */),
  "component---src-pages-vanity-shop-index-tsx": () => import("./../../../src/pages/vanity-shop/index.tsx" /* webpackChunkName: "component---src-pages-vanity-shop-index-tsx" */),
  "component---src-pages-vanity-shop-shop-our-products-tsx": () => import("./../../../src/pages/vanity-shop/shop-our-products.tsx" /* webpackChunkName: "component---src-pages-vanity-shop-shop-our-products-tsx" */),
  "component---src-pages-wedding-packages-tsx": () => import("./../../../src/pages/wedding-packages.tsx" /* webpackChunkName: "component---src-pages-wedding-packages-tsx" */),
  "component---src-templates-product-tsx-content-file-path-src-content-our-products-biojuve-index-mdx": () => import("./../../../src/templates/product.tsx?__contentFilePath=/home/runner/work/vanity-aesthetics/vanity-aesthetics/src/content/our-products/biojuve/index.mdx" /* webpackChunkName: "component---src-templates-product-tsx-content-file-path-src-content-our-products-biojuve-index-mdx" */),
  "component---src-templates-product-tsx-content-file-path-src-content-our-products-colorescience-index-mdx": () => import("./../../../src/templates/product.tsx?__contentFilePath=/home/runner/work/vanity-aesthetics/vanity-aesthetics/src/content/our-products/colorescience/index.mdx" /* webpackChunkName: "component---src-templates-product-tsx-content-file-path-src-content-our-products-colorescience-index-mdx" */),
  "component---src-templates-product-tsx-content-file-path-src-content-our-products-nutrafol-index-mdx": () => import("./../../../src/templates/product.tsx?__contentFilePath=/home/runner/work/vanity-aesthetics/vanity-aesthetics/src/content/our-products/nutrafol/index.mdx" /* webpackChunkName: "component---src-templates-product-tsx-content-file-path-src-content-our-products-nutrafol-index-mdx" */),
  "component---src-templates-product-tsx-content-file-path-src-content-our-products-skinceuticals-index-mdx": () => import("./../../../src/templates/product.tsx?__contentFilePath=/home/runner/work/vanity-aesthetics/vanity-aesthetics/src/content/our-products/skinceuticals/index.mdx" /* webpackChunkName: "component---src-templates-product-tsx-content-file-path-src-content-our-products-skinceuticals-index-mdx" */),
  "component---src-templates-product-tsx-content-file-path-src-content-our-products-zo-index-mdx": () => import("./../../../src/templates/product.tsx?__contentFilePath=/home/runner/work/vanity-aesthetics/vanity-aesthetics/src/content/our-products/zo/index.mdx" /* webpackChunkName: "component---src-templates-product-tsx-content-file-path-src-content-our-products-zo-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-our-services-hair-restoration-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/vanity-aesthetics/vanity-aesthetics/src/content/our-services/hair-restoration/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-our-services-hair-restoration-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-our-services-hormone-optimization-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/vanity-aesthetics/vanity-aesthetics/src/content/our-services/hormone-optimization/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-our-services-hormone-optimization-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-our-services-injectables-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/vanity-aesthetics/vanity-aesthetics/src/content/our-services/injectables/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-our-services-injectables-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-our-services-iv-therapy-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/vanity-aesthetics/vanity-aesthetics/src/content/our-services/iv-therapy/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-our-services-iv-therapy-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-our-services-laser-services-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/vanity-aesthetics/vanity-aesthetics/src/content/our-services/laser-services/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-our-services-laser-services-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-our-services-skin-services-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/vanity-aesthetics/vanity-aesthetics/src/content/our-services/skin-services/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-our-services-skin-services-index-mdx" */)
}

