import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Children, Fragment, ReactNode } from 'react';
import { px } from './layout/styles/classes';

export const Form = styled.form<{ submit?: boolean; gray?: boolean }>`
    ${px};
    position: relative;
    max-width: 525px;
    scroll-margin-top: 150px;
    box-sizing: content-box;

    > h2 {
        margin: 0;
        text-wrap: balance;
    }

    > p {
        margin: 16px 0 24px;
    }

    > button:last-of-type {
        display: flex;
        margin-top: 24px;
        width: 100%;
    }

    @media (min-width: 1280px) {
        > p {
            margin: 24px 0 48px;
        }

        > button:last-of-type {
            margin-top: 48px;
        }
    }

    ${({ submit }) =>
        submit &&
        css`
            select,
            textarea,
            input,
            > button {
                pointer-events: none;
                visibility: hidden;
            }
        `};
`;

export const Label = styled.label`
    display: block;
    text-transform: capitalize;
    margin-bottom: 5px;

    > span {
        color: #e46d76;
        margin-left: 4px;
    }
`;

export const Input = styled.input<{ rows?: number }>`
    width: 100%;
    display: block;
    outline: none;
    border: none;
    outline: none;
    background-color: ${({ theme }) => theme.colors.gray2};
    border-bottom: 1px solid #8d9ea5;
    transition: border-color 0.3s ease-in-out;
    color: ${({ theme }) => theme.colors.gray5};
    margin-bottom: 20px;
    padding: 7px 2px 5px;
    resize: none;

    :hover {
        border-color: ${({ theme }) => theme.colors.green4};
    }

    :focus-visible {
        border-color: ${({ theme }) => theme.colors.green4};
    }

    ::placeholder {
        color: #8d9ea5;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        text-fill-color: ${({ theme }) => theme.colors.gray5};
        color: ${({ theme }) => theme.colors.gray5};
        -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.colors.gray2} inset;
        transition:
            border-color 0.3s ease-in-out,
            background-color 50000s ease-in-out 0s;
    }

    :not(:placeholder-shown):invalid {
        border-color: #e46d76;
    }
`;

const StyledThanks = styled.div<{ submit: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: inherit;
    z-index: ${({ submit }) => (submit ? 10 : -1)};
    opacity: ${({ submit }) => (submit ? 1 : 0)};
    transform: ${({ submit }) => (submit ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease-in;
    transition-property: opacity, transform;
    background-color: ${({ theme }) => theme.colors.gray2};

    > h2 {
        margin-top: 0;
    }

    > p {
        font-size: 1.25rem;
        margin: 0;
    }

    @media (min-width: 1024px) {
        padding: 40px;
    }
`;

export const Thanks = ({ submit, children }: { submit: boolean; children?: ReactNode }) => (
    <StyledThanks submit={submit}>
        {Children.count(children) ? (
            children
        ) : (
            <Fragment>
                <h2>Thank you</h2>
                <p>Someone will be right with you shortly!</p>
            </Fragment>
        )}
    </StyledThanks>
);
