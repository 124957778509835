import { flexCenter, flexSpace, px } from '@/layout/styles/classes';
import styled from '@emotion/styled';

const Section = styled.section`
    background-color: ${({ theme }) => theme.colors.gray2};

    @media (min-width: 1024px) {
        ${flexCenter};
        justify-content: flex-end;
    }

    @media (min-width: 1600px) {
        justify-content: center;
    }
`;

const Iframe = styled.iframe`
    display: block;
    width: 100%;
    height: 95.46vw; //358px at 375px
    border: 0;
    max-height: 623px;
    max-width: 737px;

    @media (min-width: 1024px) {
        flex-shrink: 2;
    }

    @media (min-width: 1600px) {
        max-width: 670px;
    }
`;

const Hours = styled.div`
    ${px};
    padding-top: 24px;
    padding-bottom: 24px;
    max-width: 500px;

    > h2 {
        margin: 0 0 24px;
    }

    > p {
        ${flexSpace};
        align-items: flex-start;
        margin: 0;

        :not(:last-of-type) {
            margin-bottom: 24px;
        }

        > span {
            :first-of-type {
                font-weight: 600;
            }
        }
    }

    @media (min-width: 1024px) {
        padding: 0 !important;
        margin-left: 48px;
        width: 500px;
        margin-right: ${({ theme }) => theme.spacing.xl};

        > p:not(:last-of-type) {
            margin-bottom: 24px;
        }
    }

    @media (min-width: 1280px) {
        margin-left: 64px;
    }
`;

export const HoursMap = () => {
    return (
        <Section>
            <Hours>
                <h2>office hours</h2>
                <p>
                    <span>Monday</span>
                    <span>9:00 AM - 5:00 PM</span>
                </p>
                <p>
                    <span>Tuesday</span>
                    <span>9:00 AM - 5:00 PM</span>
                </p>
                <p>
                    <span>Wednesday</span>
                    <span>9:00 AM - 5:00 PM</span>
                </p>
                <p>
                    <span>Thursday</span>
                    <span>9:00 AM - 5:00 PM</span>
                </p>
                <p>
                    <span>Friday</span>
                    <span>9:00 AM - 5:00 PM</span>
                </p>
                <p>
                    <span>Saturday</span>
                    <span>Closed</span>
                </p>
                <p>
                    <span>Sunday</span>
                    <span>Closed</span>
                </p>
            </Hours>

            <Iframe
                title="my vanity aesthetics location on map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3118.2252925043995!2d-121.38930482432966!3d38.597684464333284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809adbb876aaade3%3A0x682e4fc4a8e14848!2s1800%20Professional%20Dr%2C%20Sacramento%2C%20CA%2095825%2C%20USA!5e0!3m2!1sen!2s!4v1690492619964!5m2!1sen!2s"
                allowFullScreen
                loading="lazy"
                id="map"
            ></Iframe>
        </Section>
    );
};
