export const siteInfo = {
    phone: {
        text: '(916) 407-1800',
        link: 'tel:+19164071800',
    },
    email: {
        link: 'mailto:info@myvanityaesthetics.com',
        text: 'info@myvanityaesthetics.com',
    },
    address: {
        text: '1800 Professional Drive, Sacramento, CA 95825',
        link: '',
    },
    social: {
        facebook: 'https://www.facebook.com/myvanityaesthetics/',
        instagram: 'https://www.instagram.com/myvanityaesthetics/',
        google: '',
    },
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: '/about-us/',
        links: [
            {
                text: 'our story',
                link: '/about-us/our-story/',
            },
            {
                text: 'meet the team',
                link: '/about-us/meet-the-team/',
            },
        ],
    },
    {
        text: 'our services',
        link: '/our-services/',
        links: [
            {
                text: 'injectables',
                link: '/our-services/injectables/',
            },
            {
                text: 'skin services',
                link: '/our-services/skin-services/',
            },
            {
                text: 'hair restoration',
                link: '/our-services/hair-restoration/',
            },
            {
                text: 'laser services',
                link: '/our-services/laser-services/',
            },
            {
                text: 'iV therapy',
                link: '/our-services/iv-therapy/',
            },
            {
                text: 'hormone optimization',
                link: '/our-services/hormone-optimization/',
            },
        ],
    },
    {
        text: 'our products',
        link: '/our-products/',
        links: [
            {
                text: 'nutrafol',
                link: '/our-products/nutrafol/',
            },
            {
                text: 'ZO',
                link: '/our-products/zo/',
            },
            {
                text: 'bioJuve',
                link: '/our-products/biojuve/',
            },
            {
                text: 'skinCeuticals',
                link: '/our-products/skinceuticals',
            },
            {
                text: 'colorescience',
                link: '/our-products/colorescience/',
            },
        ],
    },
    {
        text: 'gallery',
        link: '/gallery/',
        links: [
            {
                text: 'before and after',
                link: '/gallery/before-and-after/',
            },
            {
                text: 'our facility',
                link: '/gallery/our-facility/',
            },
            {
                text: 'services and procedures (coming soon)',
                link: '',
            },
        ],
    },
    {
        text: 'vanity club',
        link: '/vanity-club/',
        // links: [
        //     {
        //         text: 'alle points',
        //         link: '/vanity-club/alle-points/',
        //     },
        //     {
        //         text: 'jeuveau rewards',
        //         link: '/vanity-club/jeuveau-rewards/',
        //     },
        //     {
        //         text: 'vanity club vip (coming soon)',
        //         link: '',
        //     },
        // ],
    },
    {
        text: 'vanity shop',
        link: '/vanity-shop/',
        links: [
            {
                text: 'shop our products',
                link: '/vanity-shop/shop-our-products/',
            },
            {
                text: 'vanity swag (coming soon)',
                link: '',
            },
        ],
    },
    {
        text: 'wedding packages',
        link: '/wedding-packages/',
        links: [
            {
                text: 'wedding packages',
                link: '/wedding-packages/',
            },
            {
                text: 'bridal / bridesmaids package',
                link: '/bridal-bridesmaids-package/',
            },
        ],
    },
    {
        text: 'contact us',
        link: '',
        links: [
            {
                text: 'contact information',
                link: '/contact-information/',
            },
            {
                text: 'inquire about our services',
                link: '#inquire-about-our-services',
            },
            {
                text: 'make an appointment (coming soon)',
                link: '',
            },
        ],
    },
];

const flatLinks = (() => {
    const flat: NavLinksTypes = [];

    const pushFlat = (arr: NavLinksTypes) =>
        arr.forEach(data => {
            if (!data.links) flat.push(data);
            else {
                if (data.link) {
                    flat.push({
                        text: data.text,
                        link: data.link,
                    });
                }
                pushFlat(data.links);
            }
        });

    pushFlat(navLinks);
    return flat;
})();

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: getNavLink('about us'),
    },
    {
        text: 'our services',
        link: getNavLink('our services'),
    },
    {
        text: 'our products',
        link: getNavLink('our products'),
    },
    {
        text: 'before and after',
        link: getNavLink('before and after'),
    },

    {
        text: 'privacy policy',
        link: '/privacy-policy/',
    },
];

export function getNavLink(text: string) {
    const linkObj = flatLinks.find(data => data.text.toLowerCase() === text.toLowerCase());

    if (linkObj) return linkObj.link;
    else return '';
}
