import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Plain from './Plain';

export default styled(Plain)<{ border?: boolean; background?: boolean }>`
    width: 56px;
    height: 56px;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out;

    > svg path {
        transition: fill 0.3s ease-in-out;
    }

    ${({ theme, background, border }) =>
        background
            ? css`
                  background-color: ${theme.colors.green4};

                  > svg path {
                      fill: ${theme.colors.gray1};
                  }

                  :hover {
                      background-color: ${theme.colors.green5};
                  }

                  :focus-visible {
                      background-color: ${theme.colors.green5};
                  }
              `
            : border
            ? css`
                  border: 2px solid ${theme.colors.green4};

                  :hover {
                      background-color: rgba(202, 223, 176, 0.3);
                  }

                  :focus-visible {
                      background-color: rgba(202, 223, 176, 0.3);
                  }
              `
            : css`
                  :hover {
                      > svg path {
                          fill: ${theme.colors.green5};
                      }
                  }
                  :focus-visible {
                      > svg path {
                          fill: ${theme.colors.green5};
                      }
                  }
              `};
`;
