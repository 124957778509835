import styled from '@emotion/styled';
import Plain from './Plain';

export default styled(Plain)`
    padding: 16px 28px;
    color: ${({ theme }) => theme.colors.green4};
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.green4};
    transition: background-color 0.3s ease-in-out;
    text-transform: capitalize;

    > svg {
        margin-right: 12px;
    }

    :hover {
        background-color: rgba(202, 223, 176, 0.3);
    }

    :focus-visible {
        background-color: rgba(202, 223, 176, 0.3);
    }
`;
