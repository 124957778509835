import { CLink } from '@/CLink';
import { ReactComponent as FacebookIcon } from '@a/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '@a/icons/instagram.svg';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { footerNavLinks, siteInfo } from './NavLinks';
import { flexCenter, flexSpace, flexStart, px, py } from './styles/classes';

const Section = styled.section`
    ${py};
    ${px};
    color: ${({ theme }) => theme.colors.gray1};
    background-color: ${({ theme }) => theme.colors.gray5};

    > a:first-of-type {
        display: flex;
        transition: opacity 0.3s ease-in-out;
        margin: 0 auto 40px;
        max-width: max-content;

        > .gatsby-image-wrapper {
            max-width: 50vw;
        }

        :hover,
        :focus {
            opacity: 0.7;
        }
    }

    @media (min-width: 1024px) {
        > a:first-of-type {
            margin-bottom: 80px;
        }
    }
`;

const Flex = styled.div`
    max-width: 1040px;
    margin: 0 auto;

    > div:first-of-type {
        max-width: 570px;
    }

    @media (min-width: 1024px) {
        ${flexSpace};
        align-items: flex-start;
    }
`;

const InfoLinks = styled.p`
    ${flexStart};
    max-width: max-content;
    margin: 0;

    > span {
        min-width: 84px;
        margin-right: 16px;
        font-weight: 700;
        text-transform: capitalize;
    }

    :not(:last-of-type) {
        margin-bottom: 32px;
    }

    > a {
        text-decoration: underline;
        transition: color 0.3s ease-in-out;
        color: ${({ theme }) => theme.colors.gray1};
        word-break: break-word;
    }

    :hover,
    :focus {
        > a {
            color: ${({ theme }) => theme.colors.green3};
        }
    }
`;

const Social = styled.p`
    ${flexStart};
    align-items: center;
    margin: 32px 0;
    color: ${({ theme }) => theme.colors.gray3};

    > a {
        ${flexCenter};
        margin-left: 40px;

        > svg path {
            transition: fill 0.3s ease-in-out;
            fill: ${({ theme }) => theme.colors.gray1};
        }

        :hover,
        :focus {
            > svg path {
                fill: ${({ theme }) => theme.colors.green3};
            }
        }
    }

    @media (min-width: 1024px) {
        margin-top: 64px;
        margin-bottom: 0;
    }
`;

const Links = styled.div`
    > h4 {
        margin: 0 0 24px;
        font-size: 2rem;
        text-transform: capitalize;
    }

    > a {
        display: block;
        max-width: max-content;
        color: ${({ theme }) => theme.colors.gray1};
        text-transform: capitalize;
        word-break: break-all;

        :not(:last-of-type) {
            margin-bottom: 16px;
        }

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.green3} !important;
        }
    }
`;

export const Footer = () => {
    return (
        <Section>
            <CLink to="/">
                <StaticImage
                    src="../../../assets/images/layout/big-logo.png"
                    alt="my vanity aesthetics"
                    width={324}
                    height={178}
                />
            </CLink>

            <Flex>
                <div>
                    <InfoLinks>
                        <span>Address</span>
                        <CLink to={siteInfo.address.link}>{siteInfo.address.text}</CLink>
                    </InfoLinks>
                    <InfoLinks>
                        <span>Phone</span>
                        <CLink to={siteInfo.phone.link}>{siteInfo.phone.text}</CLink>
                    </InfoLinks>
                    <InfoLinks>
                        <span>Email</span>
                        <CLink to={siteInfo.email.link}>{siteInfo.email.text}</CLink>
                    </InfoLinks>
                    <Social>
                        Follow us:
                        <CLink to={siteInfo.social.facebook}>
                            <FacebookIcon />
                        </CLink>
                        <CLink to={siteInfo.social.instagram}>
                            <InstagramIcon />
                        </CLink>
                    </Social>
                </div>
                <Links>
                    <h4>Navigation</h4>

                    {footerNavLinks.map((link, i) => (
                        <CLink key={i} to={link.link}>
                            {link.text}
                        </CLink>
                    ))}
                </Links>
            </Flex>
        </Section>
    );
};
