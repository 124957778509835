import { Form, Input, Label, Thanks } from '@/FormComponents';
import { PrimaryBtn } from '@/buttons';
import { useState } from 'react';
import { PatternFormat } from 'react-number-format';

export const ContactForm = () => {
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = Object.fromEntries(new FormData(e.currentTarget));

        setLoading(true);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmit(true);
        setLoading(false);
        e.target.reset();
    };

    return (
        <Form onSubmit={handleSubmit} id="inquire-about-our-services">
            <h2>Inquire About Our Services</h2>
            <p>
                Looking for additional details or have inquiries about a specific treatment?
                Complete the form below, and our team will assist you with any information you need.
            </p>
            <Label htmlFor="name">Your Name</Label>
            <Input type="text" id="name" name="name" placeholder="Full Name" />

            <Label htmlFor="email">
                Email address<span>*</span>
            </Label>
            <Input
                type="email"
                id="email"
                name="email"
                required
                placeholder="e.g: example@gmail.com"
            />

            <Label htmlFor="phone">
                Phone<span>*</span>
            </Label>
            <PatternFormat
                type="tel"
                format="+1 (###) ###-####"
                customInput={Input}
                placeholder="123 456-7890"
                id="phone"
                name="phone"
                required
                pattern="^(\+1)[ ](\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$"
            />

            <Label htmlFor="message">How can we help you?</Label>
            <Input
                as="textarea"
                rows={5}
                id="message"
                name="message"
                placeholder="Write your message here"
            />

            <PrimaryBtn disabled={loading}>{loading ? 'Sending...' : 'Submit Form'}</PrimaryBtn>
            <Thanks submit={submit} />
        </Form>
    );
};
