import { css } from '@emotion/react';

const styles = css`
    line-height: 134.6%;
    font-weight: 500;
    font-family: 'Cormorant Garamond', serif;
`;

export const h1 = css`
    ${styles};
    font-size: 3.5rem;

    @media (min-width: 1280px) {
        font-size: 5rem;
    }
`;

export const h2 = css`
    ${styles};
    font-size: 2rem;
    text-transform: uppercase;

    @media (min-width: 1280px) {
        font-size: 3rem;
    }
`;

export const h3 = css`
    ${styles};
    font-size: 1.5rem;

    @media (min-width: 1280px) {
        font-size: 2.5rem;
    }
`;

export const h4 = css`
    ${styles};
    font-size: 1.25rem;

    @media (min-width: 1280px) {
        font-size: 2rem;
    }
`;

export const h5 = css`
    font-size: 1.25rem;
    line-height: 134.6%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;

    @media (min-width: 1280px) {
        font-size: 2rem;
    }
`;

export const h6 = css`
    font-size: 1.5rem;
    line-height: 134.6%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
`;

export const smTxt = css`
    font-size: 0.75rem;
`;

export const lgTxt = css`
    font-size: 1.125rem;
`;
